import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [{
        name: 'notFound',
        path: '/:path(.*)+',
        redirect: {
            name: 'index',
        },
    },
    {
        name: 'user',
        path: '/user',
        component: () =>
            import ('./view/user'),
        meta: {
            // title: '会员中心',
        },
    },
    {
        name: 'level',
        path: '/level',
        component: () =>
            import ('./view/user/level'),
        meta: {
            // title: '农场主',
        },
    },
    {
        name: 'coop',
        path: '/coop',
        component: () =>
            import ('./view/coop'),
        meta: {
            // title: '会员中心',
        },
    },
    {
        name: 'pass',
        path: '/pass',
        component: () =>
            import ('./view/user/pass'),
        meta: {},
    },
    {
        name: 'apply_list',
        path: '/apply_list',
        component: () =>
            import ('./view/user/apply_list'),
        meta: {},
    },
    {
        name: 'address',
        path: '/address',
        component: () =>
            import ('./view/user/address'),
        meta: {},
    },
    {
        name: 'rec',
        path: '/rec',
        component: () =>
            import ('./view/user/rec'),
        meta: {},
    },
    {
        name: 'rec_list',
        path: '/rec_list',
        component: () =>
            import ('./view/user/rec_list'),
        meta: {},
    },
    {
        name: 'about',
        path: '/about',
        component: () =>
            import ('./view/user/about'),
        meta: {},
    },
    {
        name: 'apply',
        path: '/apply',
        component: () =>
            import ('./view/user/apply'),
        meta: {},
    },
    {
        name: 'reward',
        path: '/reward',
        component: () =>
            import ('./view/user/reward'),
        meta: {},
    },
    {
        name: 'point',
        path: '/point',
        component: () =>
            import ('./view/user/point'),
        meta: {},
    },
    {
        name: 'ege',
        path: '/ege',
        component: () =>
            import ('./view/user/ege'),
        meta: {},
    },
    {
        name: 'well',
        path: '/well',
        component: () =>
            import ('./view/user/well'),
        meta: {},
    },
    {
        name: 'goods',
        path: '/goods',
        component: () =>
            import ('./view/user/goods'),
        meta: {},
    },
    {
        name: 'shop',
        path: '/shop',
        component: () =>
            import ('./view/user/shop'),
        meta: {},
    },
    {
        name: 'order',
        path: '/order',
        component: () =>
            import ('./view/order'),
        meta: {},
    },
    {
        name: 'team',
        path: '/team',
        component: () =>
            import ('./view/user/team'),
        meta: {},
    },
    {
        name: 'invite',
        path: '/invite',
        component: () =>
            import ('./view/user/invite'),
        meta: {},
    },
    {
        name: 'card',
        path: '/card',
        component: () =>
            import ('./view/user/card'),
        meta: {},
    },
    {
        name: 'bank',
        path: '/bank',
        component: () =>
            import ('./view/user/bank'),
        meta: {},
    },
    {
        name: 'info',
        path: '/info',
        component: () =>
            import ('./view/user/info'),
        meta: {
            // title: '会员中心',
        },
    },
    {
        name: 'cart',
        path: '/cart',
        component: () =>
            import ('./view/cart'),
        meta: {
            // title: '购物车',
        },
    },
    {
        name: 'trade',
        path: '/trade',
        component: () =>
            import ('./view/trade'),
        meta: {
            // title: '购物车',
        },
    },
   
    {
        name: 'ac_detail',
        path: '/ac_datail',
        component: () =>
            import ('./view/news/detail'),
        meta: {
            // title: '商品详情',
            refresh: true
        },
    },
    {
        name: 'ac_list',
        path: '/ac_list',
        component: () =>
            import ('./view/news/list'),
        meta: {
            // title: '商品详情',
            refresh: true
        },
    },
    {
        name: 'shop_list',
        path: '/shop_list',
        component: () =>
            import ('./view/shop_list'),
        meta: {
            // title: '商品详情',
            refresh: true
        },
    },
    
    {
        name: 'login',
        path: '/login',
        component: () =>
            import ('./view/login'),
        meta: {
            // title: '登录',
        },
    },
    {
        name: 'reg',
        path: '/reg',
        component: () =>
            import ('./view/login/reg'),
        meta: {
            // title: '登录',
        },
    },
    {
        name: 'gets',
        path: '/gets',
        component: () =>
            import ('./view/gets'),
        meta: {
            // title: '登录',
        },
    },

    {
        name: 'detail',
        path: '/datail',
        component: () =>
            import ('./view/goods/detail'),
        meta: {
            // title: '商品详情',
            refresh: true
        },
    },
    {
        name: 'search',
        path: '/search',
        component: () =>
            import ('./view/search'),
        meta: {
            // title: '登录',
        },
    },
    {
        name: 'index',
        path: '/index',
        component: () =>
            import ('./view/index'),
        meta: {
            // title: '商城首页',
        },
    },
];

const router = createRouter({
    routes,
    history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export { router };