import * as local from '@/libs/local';
import * as gongju from '@/libs/gongju';
import { Tabbar, TabbarItem } from 'vant';
// 引入英文语言包
import { Locale } from 'vant';
import en from 'vant/es/locale/lang/el-GR';
import ar from 'vant/es/locale/lang/ar-SA';
import hi from 'vant/es/locale/lang/hi-IN';
import ch from 'vant/es/locale/lang/zh-CN';
import es from 'vant/es/locale/lang/es-ES';
import { computed } from 'vue';
import 'vant/es/toast/style';
import 'vant/es/dialog/style';
import 'vant/es/popup/style';
export default {
  name: "Home",
  components: {},
  data() {
    return {
      active: 0
    };
  },
  created: function () {
    let lang = local.readInfo('setting_lang') == undefined || local.readInfo('setting_lang') == null ? 'en' : local.readInfo('setting_lang');
    //console.log("lang_init")
    if (lang !== 'ch') {
      Locale.use(lang, lang);
    } else {
      Locale.use('zh-CHS', lang);
    }
    gongju.setRem();
  }
};